import React, { useEffect, useState } from 'react';
import { apolloClinicNewLink } from 'graphql/apollo';
import { CurationLocation, MedicalCategory, MedicalCuration } from 'model/graphql';
import { convertIdWiseArrayToDictionary } from 'utils/convert-structure';
import { useRouter } from 'next/router';
import { useMedicalCategoriesLazyQuery, useMedicalCurationsLazyQuery } from 'graphql/generated/graphql';

interface CategoryDictionary {
  [index: string]: MedicalCategory;
}

interface CategoryContextValues {
  categoryDictionary: CategoryDictionary;
  curations?: MedicalCuration[];
  curationLocations?: CurationLocation[];
  selectedCuration?: MedicalCuration;
  topLevelCategories?: MedicalCategory[];
}

const CategoryContext = React.createContext<CategoryContextValues>({
  categoryDictionary: {},
});
CategoryContext.displayName = 'CategoryContext';

const CategoryProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const [getCategories, { data: categoryData }] = useMedicalCategoriesLazyQuery({ client: apolloClinicNewLink });
  const [getCurations, { data: curationData }] = useMedicalCurationsLazyQuery({ client: apolloClinicNewLink });
  const [curations, setCurations] = useState<Array<MedicalCuration>>([]);
  const [curationLocations, setCurationLocations] = useState<Array<CurationLocation>>([]);
  const [categoryDictionary, setCategoryDictionary] = useState<CategoryDictionary>({});
  const [selectedCuration, setSelectedCuration] = useState<MedicalCuration>(null);
  const [topLevelCategories, setTopLevelCategories] = useState<MedicalCategory[]>([]);

  useEffect(() => {
    const { curationId } = router.query;

    if (!curationId) {
      setSelectedCuration(null);
      setTopLevelCategories([]);
      return;
    }

    const foundCuration = curations?.find((curation: MedicalCuration) => curation?.id === curationId.toString());
    setSelectedCuration(foundCuration);

    if ((foundCuration?.medicalCategoryCurationMappings?.length ?? 0) === 0) return;

    const curatedCategories = foundCuration?.medicalCategoryCurationMappings.map(
      (medicalCategoryCurationMapping) => categoryDictionary[medicalCategoryCurationMapping.medicalCategoryId]
    );

    if (curatedCategories.length > 1) {
      setTopLevelCategories(curatedCategories);
      return;
    }

    // when curatedCategories length is 1
    const subCategory = curatedCategories[0]?.children;

    if ((subCategory?.length ?? 0) === 0) {
      setTopLevelCategories(curatedCategories);
      return;
    }

    if (subCategory.length > 1) {
      setTopLevelCategories(subCategory);
      return;
    }

    // when subCategory length is 1
    const subSubCategory = subCategory[0]?.children;

    if ((subSubCategory?.length ?? 0) === 0) {
      setTopLevelCategories(subCategory);
      return;
    }

    if (subSubCategory) {
      setTopLevelCategories(subSubCategory);
    }
  }, [router.query, curations, categoryDictionary]);

  useEffect(() => {
    getCategories();
    getCurations();

    return () => {
      // FIXME: instrument.js?ea14:109 Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
      // at CategoryProvider (webpack-internal:///./contexts/CategoryContext.tsx:40:23)
    };
  }, [getCategories, getCurations]);

  useEffect(() => {
    if (curationData?.list) {
      const { list: curationList, curationLocations } = curationData;
      setCurations(curationList);
      setCurationLocations(curationLocations);
    }
  }, [curationData]);

  useEffect(() => {
    if (categoryData) {
      const { list, tree } = categoryData;

      const flatCategoryDictionary = convertIdWiseArrayToDictionary<MedicalCategory>(list);
      setCategoryDictionary(flatCategoryDictionary); // mutated flatCategoryDictionary (filled with children) in configureTreesWithCategoryInfo
      if (tree && flatCategoryDictionary) {
        configureTreesWithCategoryInfo(tree, flatCategoryDictionary);
      }
    }
  }, [categoryData]);

  return (
    <CategoryContext.Provider
      value={{
        categoryDictionary,
        curations,
        curationLocations,
        selectedCuration,
        topLevelCategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

function configureTreesWithCategoryInfo(
  categoryEssentials: Array<{ id: string; children?: any }>,
  categoryDictionary: CategoryDictionary
): Array<MedicalCategory> {
  const categoryTrees = categoryEssentials.map((categoryEssential) => {
    if (categoryEssential.children) {
      const children = configureTreesWithCategoryInfo(categoryEssential.children, categoryDictionary);
      return {
        ...categoryDictionary[categoryEssential.id],
        children,
      };
    }
    return categoryDictionary[categoryEssential.id];
  });

  for (const categoryTree of categoryTrees) {
    if (categoryTree) {
      // eslint-disable-next-line no-param-reassign
      categoryDictionary[categoryTree.id] = categoryTree;
    }
  }

  return categoryTrees;
}

export { CategoryContext, CategoryProvider };
