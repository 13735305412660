import { css } from '@emotion/css';
import Color from 'styles/colors';

export const topRankingTabs = css`
  display: flex;
  flex-direction: row;
  min-height: auto !important;

  margin-bottom: 12px;
  .MuiTabs-flexContainer {
    display: inline-block;
  }

  .MuiTab-root {
    min-width: 0 !important;
    min-height: auto !important;
    padding: 4px !important;
    margin: 0 4px !important;

    &:first-child {
      margin-left: 20px !important;
    }
    &:last-child {
      margin-right: 20px !important;
    }

    // Body1Medium
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${Color.Gray[60]};

    &.Mui-selected {
      color: ${Color.Gray[100]};
    }
  }
`;
