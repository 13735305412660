import { css, keyframes } from '@emotion/css';
import Color from 'styles/colors';

export const content = css`
  padding-bottom: 44px;
`;

export const closeIcon = css`
  display: flex;
  justify-content: flex-end;
`;

export const overlay = (visible: boolean) => css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: ${Color.BlackAlpha['70']};
  transition: display 1s ease-in-out;

  display: ${visible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`;

const slideUp = keyframes`
  0%{
    transform:translateY(100%);
  }
  100%{
    transform:translateY(0);
  }
`;

const slideDown = keyframes`
  0%{
    transform:translateY(0);
  }
  100%{
    transform:translateY(100%);
  }
`;

export const modalWrapper = (visible: boolean) => css`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 36rem !important;
  height: 100%;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${visible ? `${slideUp} 0.5s forwards ease-in-out;` : `${slideDown} 0.8s forwards;`};
`;

export const modalBox = css`
  position: absolute;
  bottom: 0;
  max-width: 36rem;
  width: 100%;
  padding: 1em;
  background: #fff;
  border-radius: 18px 18px 0 0;
`;
