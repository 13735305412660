import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { LoaderContext } from 'contexts/LoaderContext';
import { MedicalCuration, MedicalService } from 'model/graphql';
import { AnalyticsContext, TrackingEventName } from 'contexts/AnalyticsContext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useMedicalServicesRank } from './uesRangking';
import MedicalServiceList from '../../../medical-service/components/MedicalServiceList';

import * as styles from './styles';

interface TopRankingProps {
  curations: MedicalCuration[];
}

const TopRanking: FC<TopRankingProps> = (props) => {
  const { curations } = props;
  const { medicalServicesRank, loading, fetchMedicalServicesRank } = useMedicalServicesRank();
  const { trackEvent } = useContext(AnalyticsContext);
  const { setLoaderVisible } = useContext(LoaderContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    setLoaderVisible(loading);

    return () => {
      setLoaderVisible(false);
    };
  }, [loading]);

  useEffect(() => {
    if (curations && curations.length > 0) {
      fetchMedicalServicesRank(curations[0].id);
    }
  }, [curations]);

  const onClickCardAction = useCallback(
    (medicalService: MedicalService, index: number) => {
      const curation = curations[selectedTabIndex];

      trackEvent(TrackingEventName.homeToplistProductClick, {
        productId: medicalService.clinicEventId,
        productName: medicalService.displayName,
        ranking: index + 1,
        hospitalId: medicalService.hospitalId,
        hospitalName: medicalService.medicalServiceSummary.hospitalName,
        isAffiliate: !!medicalService.clinicEventId,
        filterId: curation?.id,
        filterName: curation?.name,
      });
    },
    [curations, trackEvent]
  );

  const onClickBookmarkAction = useCallback(
    (medicalService: MedicalService, isBookmarked: boolean, index: number) => {
      trackEvent(TrackingEventName.productAddwishlistClick, {
        action: isBookmarked ? 'cancel' : 'add',
        type: 'clinichome',
        productId: medicalService.clinicEventId,
        productName: medicalService.displayName,
        ranking: index + 1,
        hospitalId: medicalService.hospitalId,
        hospitalName: medicalService.medicalServiceSummary.hospitalName,
      });
    },
    [trackEvent]
  );

  const handleTabChange = useCallback(
    (index: number) => {
      if ((!index && index !== 0) || !curations[index]) {
        setSelectedTabIndex(0);
        fetchMedicalServicesRank(curations[0].id);
        return;
      }
      trackEvent(TrackingEventName.homeToplistFilterClick, {
        filterId: curations[index]?.id,
        filterName: curations[index]?.name,
      });

      setSelectedTabIndex(index);
      fetchMedicalServicesRank(curations[index]?.id);
    },
    [curations]
  );

  return (
    <>
      <Tabs
        value={selectedTabIndex}
        textColor="inherit"
        onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
          handleTabChange(newValue);
        }}
        className={styles.topRankingTabs}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="secondary"
      >
        {curations.map((curation: MedicalCuration, index: number) => (
          <Tab key={index} value={index} label={curation.name} />
        ))}
      </Tabs>

      <SwipeableViews axis="x" index={selectedTabIndex} resistance onChangeIndex={handleTabChange}>
        {curations.map((curation: MedicalCuration) => (
          <MedicalServiceList
            key={`TopRanking_MedicalServiceList_${curation.id}`}
            medicalServices={medicalServicesRank[curation.id]}
            onBeforeCardClicked={onClickCardAction}
            onBeforeBookmarkClicked={onClickBookmarkAction}
            onListImpression={(idx) => {
              trackEvent(TrackingEventName.homeToplistImpression, {
                sequence: idx + 1,
              });
            }}
          />
        ))}
      </SwipeableViews>
    </>
  );
};
export default TopRanking;
