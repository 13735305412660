import React, { memo, useContext } from 'react';
import Image from 'next/image';

import { AnalyticsContext } from 'contexts/AnalyticsContext';
import useNavigation, { PageName } from 'hooks/useNavigation';
import FixSwipeable from 'components/atoms/FixSwipeable';
import Text from 'components/Typography/Text';
import LocationMediumIcon from 'assets/icons/ic_location_medium';
import Color from 'styles/colors';
import { LocationContext } from 'contexts/LocationContext';
import { LoaderContext } from 'contexts/LoaderContext';
import { LOCAL_STORAGE_KEY_USER_SETTING_COORDINATE } from 'types/constants';
import * as styles from './styles';

export interface LocationCurationsProps {
  curation: any;
  curationLocations: any;
}

const LocationCurations = (props: LocationCurationsProps) => {
  const { curation, curationLocations } = props;
  const { trackEvent } = useContext(AnalyticsContext);
  const { setLoaderVisible } = useContext(LoaderContext);
  const { routerPush } = useNavigation();
  const { getCurrentPositionAsync } = useContext(LocationContext);

  const handleClickLocation = async (locationInfo?: any, sequence?: number) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER_SETTING_COORDINATE, 'true');
    if (!locationInfo) {
      try {
        setLoaderVisible(true);
        const currentPosition = await getCurrentPositionAsync({ timeout: 5000 });
        routerPush(PageName.medicalService, {
          curationId: curation.id,
          lg: currentPosition.coords.longitude,
          lt: currentPosition.coords.latitude,
        });
      } catch (error) {
        console.error('currentPositionError', error);
        routerPush(PageName.medicalService, { curationId: curation.id, lg: 0, lt: 0 });
      }

      setLoaderVisible(false);
      return;
    }

    trackEvent('clinic_home_regionIcon_click', {
      sequence,
      regionId: locationInfo.id,
      regionName: locationInfo.name,
    });

    const { latitude, longitude } = locationInfo;
    routerPush(PageName.medicalService, { curationId: curation.id, lg: longitude, lt: latitude, lo: locationInfo.id });
  };

  return (
    <FixSwipeable>
      <div className={styles.container}>
        <div className={styles.currentLocation} onClick={() => handleClickLocation()}>
          <LocationMediumIcon color={Color.Primary} size={22} />
          <Text.Body1Bold className={styles.currentLocationText}>내 주변</Text.Body1Bold>
        </div>
        {curationLocations.map((locationInfo: any, index) => (
          <div
            key={locationInfo.id}
            className={styles.circle}
            onClick={() => handleClickLocation(locationInfo, index + 2)}
          >
            {locationInfo?.iconUrl && <Image src={locationInfo.iconUrl} priority layout="fill" objectFit="cover" />}
            <Text.Body1Bold className={styles.locationName}>{locationInfo.name.replace('/', ' ')}</Text.Body1Bold>
          </div>
        ))}
      </div>
    </FixSwipeable>
  );
};

export default memo(LocationCurations);
