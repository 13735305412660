import React, { memo, useContext, useEffect, useState } from 'react';

import { FunnelContext } from 'contexts/FunnelContext';

import UnfoldIcon from 'assets/icons/unfold.svg?url';
import { AnalyticsContext, TrackingEventName } from 'contexts/AnalyticsContext';
import { FAMILY_CURATION_ID } from 'types/constants';
import useNavigation, { PageName } from 'hooks/useNavigation';
import GridItem from './Item';
import * as styles from './styles';

const GridCurations = ({ curationType, curations, foldStatus }: any) => {
  const { funnel } = useContext(FunnelContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { routerPush } = useNavigation();
  const unfoldCount = 7;
  const [isFold, setIsFold] = useState(false);

  useEffect(() => {
    setIsFold(foldStatus);
  }, [foldStatus]);

  const onClickIcon = (curation: any, index: number) => {
    trackEvent('clinic_home_categoryIcon_click', {
      type: curationType,
      curationId: curation.id,
      curationName: curation.name,
      sequence: index + 1,
    });

    if (curation.id === FAMILY_CURATION_ID) {
      routerPush(PageName.contentFamily);
    } else {
      routerPush(PageName.medicalService, { curationId: curation.id, funnel });
    }
  };

  const onClickUnfold = () => {
    trackEvent('clinic_home_categoryUnfold_click');
    setIsFold(!isFold);
  };

  return (
    <div className={styles.gridBox()}>
      {curations?.slice(0, unfoldCount).map((curation: any, index) => (
        <GridItem
          key={curation.id}
          id={`CurationIcon_${curation.id}`}
          image={curation.iconUrl}
          text={curation.name}
          onClick={() => onClickIcon(curation, index)}
        />
      ))}
      {isFold ? (
        <GridItem id={`Unfold_${curationType}`} image={UnfoldIcon} text="전체보기" onClick={onClickUnfold} />
      ) : (
        curations
          .slice(unfoldCount, curations.length)
          .map((curation: any, index) => (
            <GridItem
              key={curation.id}
              id={`CurationIcon${curation.id}`}
              image={curation.iconUrl}
              text={curation.name}
              onClick={() => onClickIcon(curation, unfoldCount + index)}
            />
          ))
      )}
    </div>
  );
};

export default memo(GridCurations);
