import { css } from '@emotion/css';
import Color from 'styles/colors';

export const gridBox = () => css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1rem;
`;

export const gridItem = () => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

// mb-2 block
export const gridItemImage = () => css`
  display: block;
  width: 3.5rem;
  height: auto;
  margin-bottom: 0.5rem;
`;

export const gridItemText = () => css`
  color: ${Color.Gray['100']}; 
  text-align: center;
  overflow-wrap: break-word
  width: 5rem;
`;
