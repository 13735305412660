import { Modal } from '@material-ui/core';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LOCAL_STORAGE_KEY_NAME_LAST_VIEWED_POPUP_ID } from 'types/constants';
import BottomSheet from 'components/Overlay/BottomSheet';
import useUserAgent from 'hooks/useUserAgent';
import { useViewport } from 'react-viewport-hooks';
import { AnalyticsContext } from 'contexts/AnalyticsContext';
import useNavigation, { PageName } from 'hooks/useNavigation';
import dayjs from 'dayjs';
import ShareModalAppDownload from '../ShareModalAppDownload';
import GuidePopup from '../GuidePopup';

const CURRENT_POPUP_ID = 6; // POPUP 변경시 이 값 증가시켜야 함.
const CURRENT_POPUP_AVAILABLE_AFTER = '2023-12-30'; // 위 CURRENT 팝업을 게시할 시작 날짜
const CURRENT_POPUP_AVAILABLE_UNTIL = '2023-12-29'; // 위 CURRENT 팝업을 게시할 마지막 날짜. 빈 문자열일 경우 제한 없음(계속 게시), 과거일 경우 게시 안됨

const NoticePopup: FC = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [bottomSheetContent, setBottomSheetContent] = useState(null);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const { isBrowser } = useUserAgent();
  const bottomSheetRef = useRef<any>(null);
  const { vw } = useViewport();
  const { routerPush } = useNavigation();

  useEffect(() => {
    const popupId = localStorage.getItem(LOCAL_STORAGE_KEY_NAME_LAST_VIEWED_POPUP_ID);

    if (Number(popupId) < CURRENT_POPUP_ID) {
      setPopupOpen(true);
    } else {
      // 모바일 웹에서만 모달을 보여줌
      // eslint-disable-next-line no-lonely-if
      if (isBrowser && vw < 900 && sessionStorage.getItem('appDownload') !== 'checked') {
        setBottomSheetContent(<ShareModalAppDownload closeModal={closeBottomSheet} />);
        setShowBottomSheet(true);
      }
    }
  }, [vw, isBrowser]);

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
    sessionStorage.setItem('appDownload', 'checked');
  };

  const checkBottomSheet = (e: React.MouseEvent) => {
    if (bottomSheetRef?.current && !bottomSheetRef?.current.contains(e.target)) {
      closeBottomSheet();
    }
  };

  const onClickPopupOk = () => {
    trackEvent('clinic_home_popupOk_click');
    setPopupOpen(false);
  };

  const closePopup = () => {
    setPopupOpen(false);
    localStorage.setItem(LOCAL_STORAGE_KEY_NAME_LAST_VIEWED_POPUP_ID, CURRENT_POPUP_ID.toString());
  };

  const isNoticePopupAvailable = useMemo(
    () => dayjs().isAfter(dayjs(CURRENT_POPUP_AVAILABLE_AFTER)) &&
        dayjs().isBefore(dayjs(CURRENT_POPUP_AVAILABLE_UNTIL).add(1, 'day'), 'day'),
    []
  );

  return (
    <>
      {bottomSheetContent && (
        <BottomSheet
          showModal={showBottomSheet}
          content={bottomSheetContent}
          ref={bottomSheetRef}
          checkModal={checkBottomSheet}
          closeModal={closeBottomSheet}
        />
      )}
      <Modal
        open={isNoticePopupAvailable && isPopupOpen}
        onBackdropClick={() => {
          // 이 팝업은 백그라운드 터치 close 하지 않음, 만약 백그라운드 터치 close할 경우 아래 주석 풀 것
          setPopupOpen(false);
        }}
      >
        <GuidePopup onOk={onClickPopupOk} onClose={closePopup} />
      </Modal>
    </>
  );
};

export default NoticePopup;
