import React, { PropsWithChildren } from 'react';
import { ChipProps } from './types';
import * as styles from './styles';

const Chip = ({
  layout = '',
  children,
  size = 'xSmall',
  styleType = 'primary',
  onClick = () => null,
  ...props
}: PropsWithChildren<ChipProps>) => (
  <span
    className={styles.chip(size, styleType, layout)}
    onClick={onClick}
    {...props}
  >
    {children}
  </span>
);

export default Chip;
