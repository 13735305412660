import { css } from '@emotion/css';
import Color from 'styles/colors';

export const tabContainer = css`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const title = (selected: boolean) => css`
  color: ${selected ? Color.Gray[100] : Color.Gray[50]};
`;
