import Color from 'styles/colors';
import { SvgIconProps } from 'utils/types';

const LocationMediumIcon = (props: SvgIconProps) => {
  const { color = Color.White, size = 16 } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 13.3338C9.1111 13.3338 12.4444 9.12153 12.4444 6.6671C12.4444 4.21267 10.4546 2.22266 7.99999 2.22266C5.54539 2.22266 3.55554 4.21267 3.55554 6.6671C3.55554 9.12153 6.88888 13.3338 7.99999 13.3338ZM7.99999 8.33377C8.92046 8.33377 9.66665 7.58778 9.66665 6.6671C9.66665 5.74642 8.92046 5.00043 7.99999 5.00043C7.07951 5.00043 6.33332 5.74642 6.33332 6.6671C6.33332 7.58778 7.07951 8.33377 7.99999 8.33377Z"
        fill="current"
      />
    </svg>
  );
};

export default LocationMediumIcon;
