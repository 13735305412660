import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { UserContext } from './UserContext';

const AnalyticsGTMContext = React.createContext<any>({});

export interface FormTextFieldProps {
  children: React.ReactNode;
}

type PageEventProps = {
  event: string;
  page: string;
};

const AnalyticsGTMProvider = (props: FormTextFieldProps) => {
  const { children } = props;
  const router = useRouter();
  const [screen, setScreen] = useState();
  const [product, setProduct] = useState();
  const { auth } = useContext(UserContext);

  useEffect(() => {
    trackPageView(router.asPath);
  }, [router.asPath]);

  const trackPageView = (url: string) => {
    const pageEvent: PageEventProps = {
      event: 'pageview',
      page: url,
    };

    window?.dataLayer?.push(pageEvent);
    return pageEvent;
  };

  const trackActionByName = (
    actionName:
      | 'view_event_home'
      | 'view__event_top100'
      | 'view_event_category'
      | 'view_event_detail'
      | 'view_event_request'
      | 'view_event_review'
      | 'view_event_success'
      | 'click_event_home'
      | 'click_event_category'
      | 'click_event_detail'
      | 'click_event_request',
    buttonClickEvent?: any
  ) => {
    if (screen) {
      // @ts-ignore
      window.dataLayer.push({
        event: actionName,
        argument: {
          user: auth,
          screen,
          product,
          button: buttonClickEvent || null,
          isApp: Boolean(window.ReactNativeWebView),
        },
      });
    }
  };

  const trackButtonClick = (action: string, areaName: string, listLength: number, index: number, text?: string) => {
    if (screen) {
      // @ts-ignore
      window.dataLayer.push({
        event: action,
        argument: {
          user: auth,
          screen,
          product,
          isApp: Boolean(window.ReactNativeWebView),
          button: {
            area_name: areaName,
            list_all_index: listLength,
            index,
            text,
          },
        },
      });
    }
  };

  return (
    <AnalyticsGTMContext.Provider
      value={{
        screen,
        setScreen,
        product,
        setProduct,
        trackActionByName,
        trackButtonClick,
      }}
    >
      {children}
    </AnalyticsGTMContext.Provider>
  );
};

export { AnalyticsGTMContext, AnalyticsGTMProvider };
