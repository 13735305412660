import React, { useContext, useEffect, useMemo } from 'react';
import { HeaderContext } from 'contexts/HeaderContext';
import { AnalyticsGTMContext } from 'contexts/AnalyticsGTMContext';
import { CategoryContext } from 'contexts/CategoryContext';
import curationTypes from 'assets/data/curationTypes.json';
import AppContainer from 'components/Layout/AppContainer';
import Header from 'components/Layout/Header';
import Text from 'components/Typography/Text';
import Chip from 'components/DataDisplay/Chip';
import { curationType as CurationTypes } from 'model/graphql';
import { StyleType } from 'components/DataDisplay/Chip/types';
import useNavigation, { DeepLinkName, PageName } from 'hooks/useNavigation';
import { AnalyticsContext, TrackingEventName } from 'contexts/AnalyticsContext';
import useUserAgent from 'hooks/useUserAgent';
import { UserContext } from 'contexts/UserContext';
import EventVerticalCardList from 'components/DataDisplay/Card/ClinicEvent/VerticalCardList';
import ArrowRightBlueSmallIcon from 'assets/icons/ic_arrow_right_big.svg?url';
import newEvents from 'assets/icons/ic_new_home.svg?url';
import goodocAI from 'assets/icons/ic_speech_home.svg?url';
import { isLiveClinicEvent } from 'utils/validation';
import { useBookmarkList } from 'hooks/useBookmark';
import HomeTab from 'components/Layout/HomeTab';
import GridCurations from './components/GridCurations';
import LocationCurations from './components/LocationCurations';
import * as styles from './styles';
import NoticePopup from './components/NoticePopup';
import TopRanking from './components/TopRanking';

export default function Home() {
  const { trackEvent } = useContext(AnalyticsContext);
  const { setTitle } = useContext(HeaderContext);
  const { curations, curationLocations } = useContext(CategoryContext);
  const { isSignedIn } = useContext(UserContext);
  const { screen, setScreen, setProduct, trackActionByName } = useContext(AnalyticsGTMContext);

  const unfoldCount = 7;
  const { routerPush, pushOnelink, pushDeeplink } = useNavigation();
  const { isBrowser } = useUserAgent();

  const { fetchBookmarks, recentBookmarks } = useBookmarkList();

  useEffect(() => {
    setTitle(<HomeTab selectedIndex={0} />);
    setProduct({});
    setScreen({ screen_name: 'event_home' });

    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      fetchBookmarks();
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (screen?.screen_name === 'event_home') {
      trackActionByName('view_event_home');
    }
  }, [screen]);

  useEffect(() => {
    trackEvent('clinic_home_page_view');
  }, []);

  const bookmarkClinicEvents = useMemo(() => recentBookmarks?.map((bookmark) => bookmark.clinicEvent), [
    recentBookmarks,
  ]);

  const getCurationGroupByType = (type: string) =>
    curations
      ?.filter((curation) => curation.curationType === type)
      .sort((a: any, b: any) => a.sortIndex - b.sortIndex) || [];

  const locationCuration = useMemo(() => getCurationGroupByType(CurationTypes.location)?.[0], [curations]);

  return (
    <AppContainer>
      <div className={styles.container(isBrowser)}>
        <Header />
        <div className={styles.section}>
          {curationTypes
            .filter((curationType) =>
              ([CurationTypes.promotion, CurationTypes.topic] as Array<string>).includes(curationType.id)
            )
            .map((curationType) => (
              <div key={curationType.id} className={styles.curationSection}>
                {curationType.text && (
                  <Text.Head3 className={styles.curationTitle}>
                    {curationType.text}
                    {curationType.tag && (
                      <Chip size="xSmall" styleType={(curationType.color as StyleType) ?? 'red'}>
                        {curationType.tag}
                      </Chip>
                    )}
                  </Text.Head3>
                )}
                <div className={styles.sectionContent}>
                  <GridCurations
                    curationType={curationType.id}
                    curations={getCurationGroupByType(curationType.id)}
                    foldType="large"
                    foldStatus={getCurationGroupByType(curationType.id).length > unfoldCount}
                  />
                </div>
              </div>
            ))}
        </div>
        {locationCuration && (
          <div className={styles.section}>
            <Text.Head2 className={styles.sectionTitle}>내 지역 시술 찾기</Text.Head2>
            <LocationCurations curation={locationCuration} curationLocations={curationLocations} />
          </div>
        )}
        <div className={styles.tileSection}>
          {/* iOS 심사 거절 대응으로 인해 하단 코드 임시로 주석 처리 (챗봇 관련) */}
          {/*
          <div
            className={styles.tile(4)}
            onClick={() => {
              trackEvent(TrackingEventName.clinicHomeNewProductClick);
              routerPush(PageName.newEvents);
            }}
          >
            <Text.Body1Bold>신규 이벤트</Text.Body1Bold>
            <Text.Body3 className={styles.tileSubTitle}>혜택 가득한 새로운 이벤트!</Text.Body3>
            <img src={newEvents} alt="new events" width={57} className={styles.tileIcon} />
          </div>
          <div
            className={styles.tile(10)}
            onClick={() => {
              if (isBrowser) {
                pushOnelink(DeepLinkName.chatBot);
              } else {
                pushDeeplink(DeepLinkName.chatBot);
              }
            }}
          >
            <Text.Body1Bold>굿닥 AI</Text.Body1Bold>
            <Text.Body3 className={styles.tileSubTitle}>모르는 시술은 바로 질문!</Text.Body3>
            <img src={goodocAI} alt="goodoc AI" width={48} className={styles.tileIcon} />
          </div> */}
        </div>
        {/* <div className={styles.bestReviewSection}>
          <Text.Head2 className={styles.sectionTitle}>인기 리뷰 모아보기</Text.Head2>
          <BestReviewList />
        </div> */}
        {isSignedIn && bookmarkClinicEvents?.length > 0 && (
          <div className={styles.bookmarkSection}>
            <Text.Head2
              className={styles.sectionTitle}
              onClick={() => {
                trackEvent(TrackingEventName.clinicHomeWishlistMainClick);
                pushDeeplink(DeepLinkName.bookmark); // TODO: Onelink??
              }}
            >
              내가 찜한 이벤트
              <img src={ArrowRightBlueSmallIcon} alt="more" className={styles.eventMoreButton} />
            </Text.Head2>
            <EventVerticalCardList
              clinicEventItems={bookmarkClinicEvents}
              className={styles.bookmarkSlide}
              onBeforeCardClicked={(clinicEvent, index) => {
                trackEvent(TrackingEventName.clinicHomeWishlistMainProductClick, {
                  productId: clinicEvent.id,
                  productName: clinicEvent.title,
                  hospitalId: clinicEvent.hospital.id,
                  hospitalName: clinicEvent.hospital.name,
                  sequence: index + 1,
                  status: isLiveClinicEvent(clinicEvent),
                  loginStatus: isSignedIn,
                });
              }}
              onListImpression={(_, index) => {
                trackEvent(TrackingEventName.clinicHomeWishlistMainView, { sequence: index + 1 });
              }}
            />
          </div>
        )}
        <div className={styles.section}>
          <Text.Head2 className={styles.sectionTitle}>시술 랭킹</Text.Head2>
          <TopRanking curations={getCurationGroupByType(CurationTypes.ranking)} />
        </div>
      </div>
      <NoticePopup />
    </AppContainer>
  );
}
