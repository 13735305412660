/* eslint-disable no-confusing-arrow */
import React, { forwardRef } from 'react';
import * as styles from './styles';

const Modal = forwardRef(({ content, showModal, checkModal, closeModal }: any, ref: any) => (
  <>
    <section className={styles.overlay(showModal)} />
    <div className={styles.modalWrapper(showModal)} onClick={checkModal}>
      <div className={styles.modalBox} ref={ref}>
        <div className={styles.closeIcon}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeModal}
          >
            <path d="M5 5L19 19" stroke="#303642" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 19L19 5" stroke="#303642" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  </>
));

export default Modal;
