import { css } from '@emotion/css';
import Color from 'styles/colors';

export const card = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  background-color: ${Color.White};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 20px;
`;

export const title = css`
  color: ${Color.Gray['100']};
  word-break: keep-all;
  white-space: pre-line;
  text-align: center;
  display: flex;
  align-items: flex-start;
  margin-top: 14px;
  margin-bottom: 12px;
  border
`;

export const subtitle = css`
  color: ${Color.Gray['60']};
  word-break: keep-all;
  white-space: pre-line;
  text-align: center;
`;

export const panel = css`
  display: flex;
  width: 100%;
  margin-top: 18px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${Color.Gray[10]};
  flex-direction: row;
`;

export const label = css`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const date = css`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const closeDescriptionTextBold = css`
  color: ${Color.Gray['80']};
  word-break: keep-all;
  white-space: pre-line;
  text-align: center;
`;

export const closeDescriptionTextNormal = css`
  color: ${Color.Gray['70']};
  word-break: keep-all;
  white-space: pre-line;
  margin-top: 8px;
  text-align: center;
`;

export const button = css`
  height: 56px;
  width: 100%;
  margin-top: 20px;
`;

export const closeText = css`
  margin-top: 16px;
  color: ${Color.Gray['60']};
  text-decoration: underline;
`;
