import Color from 'styles/colors';
import { css } from '@emotion/css';
import { Size, Weight } from 'styles/font';
import { Size as typeofSize, StyleType as typeofStyleType } from './types';

export const chip = (size: typeofSize, styleType: typeofStyleType, layout: string) => css`
  ${getChip()}
  ${getSize(size)}
  ${getStyleType(styleType)}
  ${layout}
`;

const getChip = () => css`
  border: none;
  border-radius: 1.8125rem;
  cursor: default;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  word-break: nowrap;
  white-space: pre;
  width: fit-content;
  min-width: fit-content;
`;

// 지금은 text가 styled component로 되어있있어서 여기에 각각의 스타일을 넣어 주었지만
// 추후에 emotion / css로 바꾸면 해당 css를 가져오는 방식으로 변경되어야함
const getSize = (size: typeofSize) => {
  switch (size) {
    case 'xSmall':
      return css`
        height: 1.125rem;
        padding: 0 0.375rem;
        font-size: ${Size.P10};
        font-weight: ${Weight.Medium};
        line-height: ${Size.P10};
      `;
    case 'small':
      return css`
        height: 1.875rem;
        padding: 0 0.625rem;
        font-size: ${Size.P12};
        font-weight: ${Weight.Medium};
        line-height: ${Size.P16};
      `;
    case 'medium':
      return css`
        height: 2.25rem;
        padding: 0 0.75rem;
        font-size: ${Size.P14};
        font-weight: ${Weight.Regular};
        line-height: ${Size.P21};
      `;
    default:
  }
};

const getStyleType = (type: typeofStyleType) => {
  switch (type) {
    case 'primary':
      return css`
        background-color: ${Color.Primary};
        color: ${Color.White};
      `;
    case 'red':
      return css`
        background-color: ${Color.Red['60']};
        color: ${Color.White};
      `;
    case 'gray':
      return css`
        background-color: ${Color.Gray['90']};
        color: ${Color.White};
      `;
    case 'greenLight':
      return css`
        background-color: ${Color.Green['60']};
        color: ${Color.White};
      `;
    case 'grayLight':
      return css`
        background-color: ${Color.Gray['20']};
        color: ${Color.Gray['100']};
      `;
    case 'grayOutline':
      return css`
        background-color: ${Color.Transparent};
        color: ${Color.Gray['70']};
        border: 1px solid ${Color.Gray['30']};
      `;
    case 'orange':
      return css`
        background-color: ${Color.Orange['60']};
        color: ${Color.White};
      `;
    default:
  }
};
