import Text from 'components/Typography/Text';
import useNavigation, { PageName, PageNameType } from 'hooks/useNavigation';
import { FC, useMemo } from 'react';
import * as styles from './styles';

interface HomeTabProps {
  selectedIndex: number;
}
const HomeTab: FC<HomeTabProps> = (props) => {
  const { selectedIndex } = props;
  const { routerReplace } = useNavigation();

  const tabs: { title: string; pageName: PageNameType }[] = useMemo(
    () => [
      { title: '시술혜택', pageName: PageName.root },
    ],
    []
  );

  return (
    <div className={styles.tabContainer}>
      {tabs.map((tab, index) => {
        const isSelected = selectedIndex === index;
        return (
          <button
            key={tab.pageName}
            disabled={isSelected}
            onClick={() => {
              routerReplace(tab.pageName);
            }}
          >
            <Text.Head1 className={styles.title(isSelected)}>{tab.title}</Text.Head1>
          </button>
        );
      })}
    </div>
  );
};

export default HomeTab;
