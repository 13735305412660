import { FC, MouseEventHandler } from 'react';
import Button from 'components/Forms/Button';
import Text from 'components/Typography/Text';
import CloseClinicImage from 'assets/images/img_clinic_closed.svg?url';
import * as styles from './styles';

type GuidePopupProp = {
  onOk: MouseEventHandler;
  onClose?: MouseEventHandler;
};

// 홈 팝업 생성시 이 파일을 수정하여 구현할 수 있도록 Placeholder 성격으로 삭제 하지 않습니다.
const GuidePopup: FC<GuidePopupProp> = (props) => {
  const { onOk, onClose } = props;

  return (
    <div className={styles.card}>
      <img src={CloseClinicImage} alt="close clinic" />
      <Text.Head2 className={styles.title}>굿닥 시술혜택 서비스 종료 안내</Text.Head2>
      <Text.Body2 className={styles.subtitle}>
        {
          '오랫동안 굿닥 시술혜택 서비스를 이용해 주신\n' +
          '많은 고객 여러분께 진심으로 감사의 말씀 전합니다.\n\n' +
          '굿닥은 더 나은 고객 경험과 가치를 \n' +
          '제공해 드릴 수 있는 서비스에 더욱 집중하기 위해\n' +
          '시술혜택 서비스를 종료하기로 결정했습니다.\n\n' +
          '더 나은 굿닥이 되도록 노력하겠습니다.\n' +
          '감사합니다.'
        }
      </Text.Body2>
      <div className={styles.panel}>
        <div className={styles.label}>
          <Text.Body2Bold className={styles.closeDescriptionTextBold}>
            • 서비스 종료일
          </Text.Body2Bold>
          <Text.Body2 className={styles.closeDescriptionTextNormal}>
            • 신청내역 보관일
          </Text.Body2>
        </div>
        <div className={styles.date}>
          <Text.Body2Bold className={styles.closeDescriptionTextBold}>
            2023년 11월 30일
          </Text.Body2Bold>
          <Text.Body2 className={styles.closeDescriptionTextNormal}>
            2023년 12월 30일
          </Text.Body2>
        </div>
      </div>
      <Button size="medium" layout={styles.button} onClick={onClose}>
        확인했어요
      </Button>
    </div>
  );
};

export default GuidePopup;
