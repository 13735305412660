// GET_MEDICAL_SERVICES_BY_RANKING 로 lazy query로 조회하는 hook을 만들어서 사용하면 될 것 같습니다.
import { apolloClinicNewLink } from 'graphql/apollo';
import { useMedicalServicesRankingByCurationIdLazyQuery } from 'graphql/generated/graphql';
import { IdParam, MedicalService } from 'model/graphql';
import { useState } from 'react';

type RankList = {
  [curationId: string]: MedicalService[];
};

export const useMedicalServicesRank = () => {
  const [medicalServicesRank, setMedicalServiceRank] = useState<RankList>({});

  const [getMedicalServicesByRanking, { loading, variables }] = useMedicalServicesRankingByCurationIdLazyQuery({
    client: apolloClinicNewLink,
    onCompleted: (data) => {
      if (!variables) return;

      setMedicalServiceRank((prev) => ({
        ...prev,
        [variables.id]: data?.medicalServicesRankingByCurationId || [],
      }));
    },
  });

  const fetchMedicalServicesRank = (curationId: string) => {
    if (curationId) {
      const variables: IdParam = {
        id: curationId,
      };

      getMedicalServicesByRanking({
        variables,
      });
    }
  };

  return { medicalServicesRank, loading, fetchMedicalServicesRank };
};
