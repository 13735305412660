import React, { useContext } from 'react';
import CouponImage from 'assets/images/shrink_coupon.png';
import { FunnelContext } from 'contexts/FunnelContext';
import * as styles from './styles';

type Props = {
  closeModal: () => void;
};

const ShareModalAppDownload = ({ closeModal }: Props) => {
  const { funnel } = useContext(FunnelContext);
  const appDownloadHandler = () => {
    window.location.href = `https://goodoc.onelink.me/7srm?pid=Eventzone&c=webtoapp&af_dp=${encodeURIComponent(
      `goodoc://app/events?funnel=${funnel}__web_cm_home_popup`
    )}&af_web_dp=${encodeURIComponent('https://goodoc.co.kr')}&af_adset=web_cm_home_popup&af_ad=01&af_sub1=210803`;
  };
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.text}>굿닥 앱에서 더 많은</h2>
        <h2 className={styles.text}>병원 특가를 확인하세요</h2>
        <img className={styles.couponImage} src={CouponImage} alt="앱" />
        <button className={styles.appDownloadButton} onClick={appDownloadHandler}>
          앱에서 확인하기
        </button>
        <h3 className={styles.closeText} onClick={closeModal}>
          {'웹으로 계속하기 >'}
        </h3>
      </div>
    </>
  );
};

export default ShareModalAppDownload;
