import Text from 'components/Typography/Text';
import * as styles from './styles';

export default function GridItem({ id, image, text, onClick }: any) {
  return (
    <article key={id} id={id} onClick={onClick} className={styles.gridItem()}>
      <div>
        <img src={image} className={styles.gridItemImage()} alt={text} />
      </div>
      <Text.Body2 className={styles.gridItemText()}>{text}</Text.Body2>
    </article>
  );
}
