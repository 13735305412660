import { css } from '@emotion/css';
import Color from 'styles/colors';

export const container = css`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0 1.25rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const circle = css`
  min-width: 4.625rem;
  min-height: 4.625rem;
  width: 4.625rem;
  height: 4.625rem;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const locationName = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  text-align: center;
  word-break: keep-all;
  color: ${Color.White};
  background-color: ${Color.BlackAlpha['40']};
`;

export const currentLocation = css`
  ${circle}
  background-color: ${Color.Blue['20']};
`;

export const currentLocationText = css`
  text-align: center;
  word-break: keep-all;
  color: ${Color.Blue['60']};
`;
