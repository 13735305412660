import { css } from '@emotion/css';
import Color from 'styles/colors';

export const container = css`
  padding: 20px;
`;

export const text = css`
  text-align: center;
  font-size: 1.5rem;
  line-height: 150%;
  font-weight: bold;
  color: ${Color.Primary};
`;

export const closeText = css`
  text-align: center;
  font-size: 0.75rem;
  line-height: 150%;
  color: ${Color.Gray['60']};
  margin-top: 12px;
`;

export const appDownloadButton = css`
  width: 100%;
  height: 56px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${Color.Primary};
  color: ${Color.White};
  border-radius: 16px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 32px;

  :hover {
    cursor: pointer;
  }
`;

export const couponImage = css`
  margin: 18px auto 0;
`;
