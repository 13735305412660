import { css } from '@emotion/css';
import Color from 'styles/colors';

export const container = (isBrowser = true) => css`
  margin-top: ${isBrowser ? '16px' : '8px'};
`;

export const mainTitle = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.25rem;
  padding: 0.5rem 1.25rem;
`;

export const curationSection = css`
  margin-top: 0.625rem;
  margin-bottom: 0.875rem;
`;

export const section = css`
  margin-bottom: 2.5rem;
`;

export const curationTitle = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.6875rem;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0.625rem;
  gap: 0.5rem;
`;

export const sectionTitle = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 1rem;
`;

export const tileSection = css`
  ${section}
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
`;

export const tile = (rightPadding: number = 4) => css`
  flex: 1;
  display: flex;
  flex-direction: column;s
  cursor: pointer;
  border: 1px solid ${Color.Gray['40']};
  border-radius: 6px;
  padding: 14px ${rightPadding}px 10px 16px;
`;

export const tileSubTitle = css`
  color: ${Color.Gray['70']};
`;

export const tileIcon = css`
  align-self: flex-end;
  margin-top: 2px;
`;

export const eventMoreButton = css`
  margin-left: 12px;
`;

export const bookmarkSlide = css`
  margin-top: 6px;
`;

export const sectionContent = css`
  padding: 0 0.75rem;
`;

const bottomBorder = css`
  border-bottom: 10px solid ${Color.Gray['20']};
`;
export const bestReviewSection = css`
  ${section}
  overflow: hidden;
  ${bottomBorder}
`;

export const bookmarkSection = css`
  ${section}
  overflow: hidden;
  padding-bottom: 20px;
  ${bottomBorder}
`;

export const title = css`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  align-items: center;
`;

export const moreBookmarkButton = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 67px;
  flex-shrink: 0;
`;
